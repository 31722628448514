<template>
  <div class="simulation">
    <div class="part">
      <el-card>
        <el-row :gutter="100" justify="space-around">
          <el-col :span="8" v-for="(item, index) in examList" :key="index">
            <div>
              <p>{{ item.title }}</p>
              <div v-html="item.content"></div>
              <el-button
                type="danger"
                class="exam"
                @click="examing(item.paper_id, item.class)"
                >开始模拟练习</el-button
              >
            </div>
          </el-col>
          <el-col :span="24">
            <div class="tip">
              <div class="text">
                <h2>友情提示</h2>
                <p>
                  1.付费后学员即可获得培训课程、模拟练习、正式考试及证书制作的服务；
                </p>
                <p>
                  2.通过模拟练习，结合培训课程，学员可以进行网培师相关知识的学习；
                </p>
                <p>
                  3.模拟练习只为学员提供练习和提高相关知识水平的作用，结果不记录成绩。
                </p>
              </div>
              <div class="qrtip">
                <p>点击按钮或扫描二维码</p>
                <p>交费后可查看所有学习资料</p>
                <p>
                  <el-button type="danger" class="go" @click="course"
                    >查看课程目录>></el-button
                  >
                </p>
                <p>
                  <el-button type="danger" class="go" @click="study"
                    >前往学习及评测>></el-button
                  >
                </p>
              </div>
              <div class="qrcode">
                <img src="../assets/images/exam/qrcode.jpg" alt srcset />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 支付弹框 -->
    <el-dialog
      title="支付"
      :visible.sync="dialogVisible"
      width="550px"
      v-loading="loading"
      :close-on-click-modal="false"
      center
      class="payStyle"
    >
      <div>
        <span>付费内容:</span>
        培训教材、评测练习、正式评测、评测审核、证书制作、证书颁发。
      </div>
      <div v-if="payRadio !== '1'">
        <span>输入兑换码: </span>
        <el-input
          placeholder="请输入兑换码"
          class="redeem"
          v-model="redeemData"
        ></el-input>
        <p class="warnRed">*请注意区分大小写</p>
      </div>
      <div v-else><span>支付金额: </span>{{ this.$price }}</div>
      <div>
        <span>支付方式:</span
        ><el-radio v-model="payRadio" label="1" border>微信支付</el-radio>
        <el-radio v-model="payRadio" label="2" border>兑换码</el-radio>
      </div>
      <el-button type="danger" @click="toPay" v-if="payRadio == '1'"
        >立即支付</el-button
      >
      <el-button type="danger" @click="toRedeem" v-else>立即兑换</el-button>
    </el-dialog>
    <!-- 认证弹框 -->
    <el-dialog :visible.sync="showInfo" width="500px" v-loading="loading">
      <el-form
        ref="ruleForm"
        label-position="left"
        label-width="150px"
        :model="form"
        :rules="rules"
        hide-required-asterisk
      >
        <p>
          请确认您的身份信息，一旦认证成功将不能再次进行修改。如因身份信息有误导致无法考试、获取证书，本平台将不会退回费用。
        </p>
        <br />
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idcard">
          <el-input
            v-model="form.idcard"
            placeholder="请输入身份证号"
            @paste.native.capture.prevent="()=>{return false}"
          ></el-input>
        </el-form-item>
        <el-form-item label="请再次确认身份证号:" prop="rIdcard">
          <el-input
            v-model="form.rIdcard"
            placeholder="请输入身份证号"
            @paste.native.capture.prevent="()=>{return false}"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="danger" size="small" @click="toRZ">认证</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 考试项选择弹框 -->
    <el-dialog
      title="试题选择"
      :visible.sync="examSelect"
      width="450px"
      v-loading="loading"
      center
    >
      <div class="examselect">
        <p>1.请选择一个您最擅长的视频编辑软件；</p>
        <p>2.您的选择将决定评测中视频剪辑部分试题，请酌情选择；</p>
        <!-- <p>3.<span>选择后将无法更改其他视频编辑软件，请慎重！</span></p> -->
        <el-radio-group v-model="radio" style="padding-left:10px;">
          <el-radio
            v-for="item in radioOptions"
            :key="item.id"
            :label="item.id"
            >{{ item.title }}</el-radio
          >
        </el-radio-group>
        <div class="text-center">
          <el-button type="primary" @click="isSelectSure">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 课表弹框 -->
    <el-dialog
      :visible.sync="showclass"
      v-loading="loading"
      title="课程目录"
      class="dialog_table"
      top="10vh"
      width="120vh"
    >
      <div class="container">
        <Classtable></Classtable>
      </div>
    </el-dialog>
    <!-- 认证提示 -->
    <el-dialog title="提示" :visible.sync="warningDialog" width="400px">
      <span>认证后将不能修改姓名和身份证号, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="warningDialog = false">取 消</el-button>
        <el-button type="primary" @click="warningToRZ">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Classtable from '../components/Classtable'
let _this
export default {
  name: 'Simulation',
  components: { Classtable },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入身份证号'))
      } else if (value !== this.form.idcard) {
        callback(new Error('两次输入不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        name: {
          required: true,
          message: '请输入姓名',
          trigger: 'blur',
        },

        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '请输入正确的身份证号',
            trigger: 'blur',
          },
        ],
        rIdcard: { required: true, validator: validatePass2, trigger: 'blur' },
      },
      payRadio: '1',
      toForm: {},
      cookieId: '',
      obj: {},
      form: {},
      dialogVisible: false,
      warningDialog: false,
      showInfo: false,
      radioOptions: [],
      examSelect: false,
      loading: false,
      redeemData: '',
      order_number: '',
      radio: '',
      studyed: false,
      showclass: false,
      exam: [
        {
          id: '725',
          url: require('../assets/images/exam/img2.png'),
          title: '教学综合能力评测',
          link: 'https://ks.netban.cn/entrance/725',
          istrue: undefined,
        },
        {
          id: '714',
          url: require('../assets/images/exam/img3.png'),
          title: '网培政策规范评测',
          link: 'https://ks.netban.cn/entrance/714',
          istrue: undefined,
        },
        {
          id: 'null',
          url: require('../assets/images/exam/img1.png'),
          title: '教学数字化能力评测',
          link: '1',
          istrue: 'simulation',
        },
      ],
      token: '',
      redeem_number: '',
      examList: [],
    }
  },
  created() {
    if (this.$store.state.wps_user.mobile) {
      _this = this
      this.form = {
        name: this.$store.state.wps_user.name,
        idcard: this.$store.state.wps_user.idcard,
      }
      this.obj = this.$store.state.wps_user
      this.getExam()
    } else {
      this.$message.warning('您还未登录，请先登录！')
      this.$router.push('Login')
    }
  },
  methods: {
    init() {},
    getExam() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'exam' + '/0')
        .then(data => {
          if (data.data.code == 200) {
            let res = data.data.data
            res.forEach(item => {
              if (item.type == 0) {
                this.examList.push(item)
              }
            })
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getTest() {
      if (!_this.obj.is_verfy) {
        _this.warningDialog = true
      } else {
        if (JSON.parse(this.toForm.istrue).length > 0) {
          _this.papers(this.toForm.istrue)
        } else {
          _this.setNetbanCookie(this.toForm.id)
        }
      }
    },
    papers(istrue) {
      this.radioOptions = JSON.parse(istrue)
      this.radio = this.radioOptions.id
      this.examSelect = true
    },
    examing(id, istrue) {
      _this.studyed = false
      if (this.$store.state.wps_user.mobile) {
        // this.toForm.url = url
        this.toForm.id = id
        this.toForm.istrue = istrue
        this.cookieId = id
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            _this.token = data.data.token
            if ((data.data.code == 403) | (data.data.code == 500)) {
              _this.$message.error(data.data.msg)
              return
            }
            if (data.data.code == 200) {
              //未支付
              _this.dialogVisible = true
            } else {
              this.getTest()
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('您还未登录，请先登录！')
      }
    },
    setNetbanCookie(id) {
      _this.$http
        .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
        .then(data => {
          _this.token = data.data.token
          if (_this.studyed) {
            window.open(
              'http://netban.cn/api/user/synwp/' +
                _this.token +
                '?url=http://custom.netban.cn/app/pc/?id=T20326'
            )
            return
          }
          if (id) {
            window.open(
              'http://netban.cn/api/user/synwp/' + _this.token + '?eid=' + id
            )
          } else {
            window.open(
              'http://netban.cn/api/user/synwp/' +
                _this.token +
                '?eid=' +
                this.cookieId
            )
          }
        })
    },
    toRedeem() {
      //兑换码
      _this.loading = true
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'exchange', {
          good_id: 1,
          numbers: this.redeemData,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          _this.loading = false
          if (data.data.code == 403 || data.data.code == 500) {
            _this.$message.error(data.data.msg)
            return
          }
          _this.redeem_number = data.data.data
          if (this.redeem_number) {
            window.location.href =
              'http://pay.netban.cn/pay/' + this.redeem_number //跳转至支付页面
          }

          if (_this.studyed) {
            _this.setNetbanCookie()
          }
          this.getTest()
          _this.dialogVisible = false
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          _this.loading = false
          console.log(err)
        })
    },
    toPay() {
      //去支付
      this.toExam()
    },
    toExam() {
      this.cookieId = this.toForm.id
      let subsystem_url = window.location.href
      _this.$http
        .post(process.env.VUE_APP_URL + 'order', {
          good_id: 1,
          subsystem_url: subsystem_url,
        })
        .then(data => {
          if (data.data.code == 200) {
            //未付款
            _this.order_number = data.data.data
            window.location.href =
              'http://pay.netban.cn/pay/' + this.order_number //跳转至支付页面
          } else if (data.data.code == 201 && data.data.token != '') {
            //已付款
            if (_this.studyed) {
              _this.setNetbanCookie()
            }
            this.getTest()
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    toRZ() {
      let _this = this
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.$http
            .post(process.env.VUE_APP_URL + 'verify', this.form)
            .then(data => {
              _this.loading = true
              if (data.data.code == 403) {
                _this.$message.error(data.data.msg)
                _this.loading = false
                return
              }
              _this.loading = false
              _this.showInfo = false
              _this.obj.name = _this.form.name
              _this.obj.idcard = _this.form.idcard
              _this.obj.is_verfy = 1
              _this.$store.commit('setUser', _this.obj)
              _this.$message.success('认证成功！去参与测评吧')
              this.getTest()
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              _this.loading = false
              console.log(err)
            })
        }
      })
    },
    warningToRZ() {
      this.showInfo = true
      this.warningDialog = false
    },
    isSelectSure() {
      this.setNetbanCookie(this.radio)
      this.examSelect = false
    },
    study() {
      _this.studyed = true
      if (this.$store.state.wps_user.mobile) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            _this.token = data.data.token
            if (data.data.code == 403 || data.data.code == 500) {
              _this.$message.error(data.data.msg)
              return
            }
            if (data.data.code == 200) {
              _this.dialogVisible = true
            } else {
              _this.setNetbanCookie()
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('您还未登录，请先登录！')
      }
    },
    course() {
      this.showclass = true
    },
  },
}
</script>
<style lang="less" scoped>
.simulation {
  .part {
    max-width: 1200px;
    margin: 0px auto;
    padding: 40px 0px;
    text-align: center;
    font-weight: bold;
    img {
      width: 100%;
    }
    /deep/.el-card__body {
      padding: 20px 50px;
      p {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        // margin: 61px 0px;
        margin: 40px 0px;
      }
      .exam {
        width: 180px;
        height: 40px;
        background-color: #960f23 !important;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        // margin-top: 31px;
      }
    }
    .tip {
      height: 180px;
      background: #fffbf7;
      margin-top: 72px;
      div {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      p {
        margin: 0px;
        text-align: left;
      }
      .text {
        float: left;
        text-align: left;
        margin-left: 91px;
        h2 {
          font-size: 16px;
          font-weight: bold;
          color: #666666;
          line-height: 24px;
          margin-bottom: 17px;
        }
        p {
          font-size: 14px;
          color: #666666;
          line-height: 24px;
          font-weight: normal;
        }
      }
      .qrcode {
        float: right;
        margin-right: 16px;
      }
      .qrtip {
        float: right;
        text-align: left;
        margin-right: 108px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .go {
          width: 191px;
          height: 40px;
          background: #ce9a6c !important;
          border: none;
          border-radius: 4px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
        p {
          line-height: 16px;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .el-dialog {
    .el-input {
      width: 270px;
      margin: 10px 0;
    }
    .el-button--danger {
      background-color: #960f23;
      width: 100px;
      margin-top: 50px;
    }
  }
  .uploading {
    margin-bottom: 100px;
  }
  .redeem {
    padding: 10px 10px 0 0;
  }
  .toRedeem {
    width: 120px !important;
    margin-left: 14px;
  }
  .cancel {
    margin-right: 225px;
  }
  .examselect {
    p {
      margin: 12px;
      font-size: 15px;
      span {
        color: red;
      }
    }
    div {
      padding-top: 20px;
      .el-button {
        width: 110px;
      }
    }
  }
  .el-radio {
    margin: 6px;
  }
  .download {
    padding-top: 10px;
    font-size: 15px;
    .el-link {
      color: red;
      font-size: 16px;
    }
  }
  .toPdf {
    h2 {
      margin-bottom: 25px;
      font-size: 20px;
      color: #960f23;
    }
    div {
      margin: 5px;
      font-size: 16px;
    }
  }
  .dialog_table {
    .container {
      height: 70vh;
    }
    /deep/.el-dialog__header {
      text-align: center;
      font-weight: bold;
      color: #000000;
    }
    /deep/.el-dialog__body {
      padding: 10px 100px 20px;
    }
  }
  /deep/.el-form-item__label {
    line-height: 60px;
  }
  .warnRed {
    color: red;
    padding-left: 100px;
    font-size: 13px;
  }
  .payStyle {
    div {
      font-size: 15px;
      margin: 10px;
    }
    span {
      font-size: 16px;
      color: #960f23;
      padding-right: 10px;
    }
    .el-button {
      margin-left: 205px;
    }
  }
}
</style>
